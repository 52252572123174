<template>
  <div class="StaffOrganisations">
    <banded-section
      title="Organisation"
      tooltip="This page is visible to hypefactors staff only."
      :collapsable="false"
    >
      <div class="StaffNewsrooms-Search">
        <heading size="3">
          Organisations
        </heading>
        <div v-loading="fetchingOrganisations || fetchingAvailableSubscriptionPlans" class="StaffOrganisations-List">
          <form enctype="multipart/form-data" @submit.prevent="submit">
            <div class="notification is-warning">
              Please ensure you've started the invoicing when you as a staff member upgrade a subscription from freemium to a paid plan
            </div>

            <form-field
              :validator="$v.form.organisation_id"
              label="Organisation"
            >
              <basic-select
                :value="form.organisation_id"
                :values="fetchedOrganisations"
                :remote-method="queryForOrganisations"
                :loading="fetchingOrganisations"
                remote
                filterable
                clearable
                label-prop="name"
                value-prop="id"
                key-prop="id"
                placeholder="Choose an Organisation"
                popper-class="OrganisationsModal"
                @input="handleOrganisationSelect"
              />
            </form-field>

            <form-field :validator="$v.form.plan" label="Subscription Plan">
              <basic-select
                v-model="form.plan"
                :values="plans"
                label-prop="internal_name"
                value-prop="slug"
                key-prop="slug"
                @change="form.commitment = null"
              >
                <span slot-scope="{ item }">
                  <span v-if="item.internal_name">{{ item.internal_name }}</span>
                  <span v-else>{{ $t(item.name) }}</span>
                </span>
              </basic-select>
            </form-field>
            <form-field :validator="$v.form.commitment" label="Commitment Plan">
              <basic-select
                v-model="form.commitment"
                :values="commitments"
                label-prop="name"
                value-prop="slug"
                key-prop="slug"
              />
            </form-field>

            <!--<div v-show="customPlanChosen">-->
            <!--<heading size="5">Custom Plan Settings</heading>-->
            <!--<custom-plan-builder-->
            <!--v-model="form.customPlan"-->
            <!--:validator="$v.form.customPlan"-->
            <!--class="m-b-m"-->
            <!--/>-->
            <!--</div>-->

            <button
              :class="{ 'is-loading': form.isLoading }"
              :disabled="form.isLoading || subscriptionPlanIsUnchanged"
              type="submit"
              class="button is-primary"
            >
              Change Subscription Plan
            </button>
          </form>
        </div>
      </div>
    </banded-section>
  </div>
</template>

<script>
import Form from '@/services/forms/Form'
import { required } from 'vuelidate/lib/validators'
import { PaymentPlan } from '@/models/PaymentPlan'

import { BillingApiService } from '@/services/api/BillingApiService'
import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'

// import CustomPlanBuilder from '@/components/billing/CustomPlanBuilder'

function createForm () {
  return new Form({
    organisation_id: { value: null, rules: { required } },
    plan: { value: null, rules: { required } },
    commitment: { value: null, rules: { required } },
    customPlan: {
      value: {
        period: '',
        amount: 0,
        limits: {}
      },
      rules: {
        // period: { required },
        // amount: { required }
      }
    }
  })
}

/**
 * Staff Organisations Management page.
 * TODO: Will require billing updating later.
 * @module StaffOrganisations
 */
export default {
  components: {
    // CustomPlanBuilder
  },
  mixins: [debounceMixin(['queryForOrganisations'], 250)],

  data: () => ({
    form: createForm(),
    fetchedOrganisations: [],
    fetchingOrganisations: false,
    fetchedAvailablePlans: [],
    fetchingAvailableSubscriptionPlans: false
  }),

  computed: {
    subscriptionPlanIsUnchanged () {
      if (!this.fetchedOrganisations.length) return true
      // TODO (BRUNO): Temporary solution due to some seeded Organisations not having a subscription
      if (!this.form.plan || !this.form.commitment) return true
      const organisation = this.findOrganisationById(this.form.organisation_id)
      if (organisation) {
        return this.form.commitment === this.$safeGet(organisation, 'subscription.data.commitment.slug')
      }
      return false
    },

    customPlanChosen () {
      return this.form.plan === 'custom'
    },

    plans () {
      return this.fetchedAvailablePlans.filter(plan => plan.slug !== 'enterprise')
    },

    commitments () {
      const plan = this.fetchedAvailablePlans.find(plan => plan.slug === this.form.plan)
      return plan ? plan.commitments : []
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  mounted () {
    this.queryForOrganisations()
    this.fetchAvailableSubscriptionPlans()
  },

  methods: {
    queryForOrganisations (search = '') {
      this.fetchingOrganisations = true

      const payload = {
        params: {
          search,
          limit: 20,
          include: ['subscription']
        }
      }

      return this.fetchOrganisations(payload)
    },

    fetchAvailableSubscriptionPlans () {
      this.fetchingAvailableSubscriptionPlans = true

      BillingApiService.fetchPlans()
        .then((response) => {
          this.fetchedAvailablePlans = response.map(plan => new PaymentPlan(plan))
        })
        .catch((error) => {
          this.$displayRequestError(error, this.$t('errors.cannot_fetch_subscription_plans'))
        })
        .finally(() => {
          this.fetchingAvailableSubscriptionPlans = false
        })
    },

    fetchOrganisations (payload) {
      this.$api.get('staff/organisations', payload)
        .then((response) => {
          this.fetchedOrganisations = response.data.data
        })
        .catch((error) => {
          this.$displayRequestError(error, this.$t('errors.cannot_fetch_organisations'))
        })
        .finally(() => {
          this.fetchingOrganisations = false
        })
    },

    handleOrganisationSelect (value) {
      this.form.organisation_id = value
      this.form.plan = null
      this.form.commitment = null
      const organisation = this.findOrganisationById(value)
      const subscription = organisation.subscription.data
      const plan = subscription.plan
      const commitment = subscription.commitment
      if (plan) {
        this.form.plan = plan.slug
        this.form.commitment = commitment.slug
      }
    },

    /**
     * Find an organisation by ID
     * @param id
     * @return {HF_Organisation}
     */
    findOrganisationById (id) {
      return this.fetchedOrganisations.find((organisation) => organisation.id === id)
    },

    async submit () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      try {
        await this.$confirm('Are you sure you want to change the subscription plan for the Organisation? This will also force sign-out all members of its members.')
        this.form.setPayloadTransformer((payload) => {
          return {
            organisation_id: payload.organisation_id,
            plan: payload.commitment
          }
        })
        this.form.put(`/staff/organisations/${this.form.organisation_id}/change-plan`)
          .then(() => {
            this.$notify.success('The subscription plan changed successfully.')
            this.form.reset()
            this.$v.form.$reset()
            this.queryForOrganisations()
            this.fetchAvailableSubscriptionPlans()
          })
          .catch((err) => {
            this.$displayRequestError(err)
          })
      } catch (err) {}
    }
  }
}
</script>
